<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
export type BrandSelectionFormType = {
  brand: string | undefined
}

const brandSelectionForm = defineModel<BrandSelectionFormType>(
  'brandSelectionForm',
  {
    required: true,
  }
)

const emit = defineEmits<{
  submitBrandForm: [void]
}>()

const handleSubmit = () => {
  emit('submitBrandForm')
}
</script>

<template>
  <div>
    
<FormKitLazyProvider config-file="true">
<div class="flesidebar-headerx flex-col">
      <div class="text-light-6 mb-8">
        <p>
          {{ $ts('bookAnAppointment.brandSelection.sentenceBrandSelection') }}
        </p>
        <p>
          {{ $ts('bookAnAppointment.brandSelection.chooseBrand') }}
        </p>
      </div>
      <div>
        <FormKit
          id="BOOK_AN_APPOINTMENT_BRAND_FORM"
          v-model="brandSelectionForm"
          type="form"
          :classes="{
            messages: 'hidden',
            actions: 'hidden',
          }"
          @submit="handleSubmit"
        >
          <FormKit
            id="brand"
            type="myRadio"
            name="brand"
            :options="[
              { label: 'Giorgio Armani', value: 'giorgio-armani' },
              { label: 'Emporio Armani', value: 'emporio-armani' },
              { label: 'EA7', value: 'ea7' },
              { label: 'Armani Exchange', value: 'armani-exchange' },
              { label: 'Armani Casa', value: 'armani-casa' },
            ]"
            :classes="{
              outer: 'flex flex-col gap-6',
              options: 'flex flex-col gap-4 lg:gap-6 test',
              label: 'text-book-5',
              message: 'capitalize',
            }"
            validation="required"
            :validation-messages="{
              required: `${$ts('bookAnAppointment.requiredFields')}`,
            }"
            validation-visibility="submit"
          />
        </FormKit>
      </div>
    </div>
</FormKitLazyProvider>

  </div>
</template>
